import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
} from '@angular/core';

import { InsecureUrl } from 'src/app/ajs-upgraded-providers';

import { AttributeDataService } from '../../services/attribute-data.service';
import { ComponentsService } from '../../services/components.service';

import { environment } from 'src/environments/environment';
import {
  VideoStreamingValidationResult,
  VideoStreamingValidationService,
} from '../services/video-streaming-validation-service.service';

@Component({
  selector: 'template-component-video-streaming',
  templateUrl: './video-streaming.component.html',
  styleUrls: ['./video-streaming.component.scss'],
})
export class VideoStreamingComponent implements OnInit {
  spinner = false;
  componentId: string;
  source: string;
  volume: number;
  validationStatus: VideoStreamingValidationResult['status'];

  constructor(
    private elementRef: ElementRef,
    private componentsFactory: ComponentsService,
    private attributeDataService: AttributeDataService,
    private insecureUrl: InsecureUrl,
    private videoStreamingService: VideoStreamingValidationService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    componentsFactory.registerDirective({
      type: 'rise-video-streaming',
      element: this.elementRef.nativeElement,
      show: () => {
        this.componentId = this.componentsFactory.selected.id;

        this.load();
      },
    });
  }

  load() {
    this.source = this.attributeDataService.getAvailableAttributeData(
      this.componentId,
      'source'
    );
    this.volume =
      this.attributeDataService.getAvailableAttributeData(
        this.componentId,
        'volume'
      ) || 0;
  }

  ngOnInit(): void {}

  validateSource() {
    //clear the error
    this.validationStatus = undefined;

    const _source = !this.source ? '' : this.source.trim();
    if (_source === '') {
      //empty string is allowed
      return true;
    }

    if (this.insecureUrl(_source)) {
      this.source = _source.replace('http://', 'https://');
    }

    if (this.videoStreamingService.validateHLSUrl(_source)) {
      return true;
    }

    this.validationStatus = 'INVALID';
    return false;
  }

  async saveSource() {
    if (this.validateSource()) {
      this.validationStatus = 'VALID';
      this.saveEnvironment();
      this.attributeDataService.setAttributeData(
        this.componentId,
        'source',
        this.source
      );
      this.spinner = true;
      this.changeDetectorRef.detectChanges();
      const result = await this.videoStreamingService.validateHLSContent(
        this.source
      );
      this.validationStatus = result.status;
      this.spinner = false;
      this.changeDetectorRef.detectChanges();
    }
  }

  saveVolume() {
    this.saveEnvironment();
    this.attributeDataService.setAttributeData(
      this.componentId,
      'volume',
      this.volume
    );
  }

  saveEnvironment() {
    this.attributeDataService.setAttributeData(
      this.componentId,
      'environment',
      environment.production ? 'prod' : 'test'
    );
  }
}
