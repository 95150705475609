import { Component, ElementRef, ChangeDetectorRef } from '@angular/core';
import { environment } from 'src/environments/environment';

import { ComponentsService } from '../../services/components.service';
import { AttributeDataService } from '../../services/attribute-data.service';
import { OAuthService } from 'src/app/components/content-oauth/services/oauth.service';
import { SocialMediaService } from '../services/social-media.service';
import { ModalService } from 'src/app/components/modals/modal.service';
import { TemplateEditorService } from '../../services/template-editor.service';
import { TemplateEditorUtilsService } from '../../services/template-editor-utils.service';
import { FeaturesService } from 'src/app/components/plans/features.service';

const FB_PAGE_PROVIDER = 'facebook-business';
const FB_USER_PROVIDER = 'facebook-consumer';
const IG_PAGE_PROVIDER = 'instagram-business';


const FB_PAGE_SCOPE = "pages_show_list,pages_read_engagement,pages_read_user_content";
const FB_USER_SCOPE = "user_posts";
const IG_PAGE_SCOPE = "instagram_business_basic";

export interface Feed {
  userAccount?: string;
  pageId?: string;
  pageName?: string;
  userDisplayName?: string;
}

export enum FeedType {
  FB_PAGE = 'fb_page',
  FB_USER = 'fb_user',
  IG_PAGE = 'ig_page',
}

export enum LayoutType {
  SINGLE_POST = 'single_post',
  WATERFALL = 'waterfall',
}

@Component({
  selector: 'template-component-social-media-posts',
  templateUrl: './social-media-posts.component.html',
  styleUrls: ['./social-media-posts.component.scss'],
})
export class SocialMediaPostsComponent {

  FeedType: typeof FeedType = FeedType; // make enum available in template
  LayoutType: typeof LayoutType = LayoutType; // make enum available in template
  public readonly FEEDS_COUNT = Object.keys(FeedType).length;
  public readonly MIN_POSTS = 1;
  public readonly MAX_POSTS = 50;
  public readonly MIN_DURATION = 1;
  public readonly MAX_DURATION = 999999;
  public readonly DEFAULT_NUMBER_OF_POSTS = 20;

  public componentId: string;
  public layout: LayoutType;
  public numberOfPosts: number;
  public availableFeeds = [];
  public showCaption: boolean;
  public hideVideos: boolean;
  public shuffleOrder: boolean;
  public duration: number;
  public scrollSpeed: string;
  public hideOldPosts: string;
  public refresh: number;
  public feeds = new Map<FeedType, Feed>();
  public currentView: string;
  public selectedFeed: {type: FeedType, feed: Feed};
  public availablePages: { id: string; name: string; picture: string; }[];
  public selectedPage: { id: string; name: string; };
  public spinner: boolean;
  public userAccount: string;
  public authenticateFailed: boolean;
  public revokeFailed: boolean;
  public revokeFailedProviderName: string;
  public pagesApiFailed: boolean;

  constructor(
    private elementRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
    private componentsFactory: ComponentsService,
    private attributeDataService: AttributeDataService,
    private authService: OAuthService,
    private socialMediaService: SocialMediaService,
    private modalService: ModalService,
    private templateEditorService: TemplateEditorService,
    private templateEditorUtilsService: TemplateEditorUtilsService,
    private featuresService: FeaturesService,
  ) {
    this.userAccount = this.authService.getUserIdentifier(null);

    this.componentsFactory.registerDirective({
      type: 'rise-social-media-posts',
      element: this.elementRef.nativeElement,
      show: () => {
        this.componentId = componentsFactory.selected.id;
        this.selectedPage = null;
        this.authenticateFailed = false;
        this.revokeFailed = false;
        this.pagesApiFailed = false;
        this.spinner = false;
        this.currentView = null;

        this.load();
      },
      onBackHandler: () => {
        // if currentView is defined, then always go back to the main view
        if (this.currentView) {
          this.currentView = null;
          return true;
        }
      },
      getLabel: (): string => {
        if (this.currentView) {
          return 'Add Feed';
        }
      },
    });
  }

  load() {
    const sources = this.attributeDataService.getAvailableAttributeData(this.componentId, 'sources') || new Map<FeedType, Feed>();
    this.feeds = this._sourcesToFeeds(sources);
    this.layout = this.attributeDataService.getAvailableAttributeData(this.componentId, 'layout') || LayoutType.WATERFALL;
    this.numberOfPosts = this.attributeDataService.getAvailableAttributeData(this.componentId, 'numberOfPosts') || this.DEFAULT_NUMBER_OF_POSTS;
    const showCaption = this.attributeDataService.getAvailableAttributeData(this.componentId, 'showCaption');
    this.showCaption = showCaption == null ? true : showCaption;
    const hideVideos = this.attributeDataService.getAvailableAttributeData(this.componentId, 'hideVideos');
    this.hideVideos = hideVideos == null ? true : !!hideVideos;
    const order = this.attributeDataService.getAvailableAttributeData(this.componentId, 'order');
    this.shuffleOrder = order === "shuffle";
    this.duration = this.attributeDataService.getAvailableAttributeData(this.componentId, 'duration') || 10;
    this.scrollSpeed = this.attributeDataService.getAvailableAttributeData(this.componentId, 'scrollSpeed') || 'medium';
    this.hideOldPosts = this.attributeDataService.getAvailableAttributeData(this.componentId, 'hideOldPosts') || '';
    this.refresh = this.attributeDataService.getAvailableAttributeData(this.componentId, 'refresh') || 720;
  }

  save() {
    const sources = this._feedsToSources(this.feeds);
    this.attributeDataService.setAttributeData(this.componentId, 'sources', sources);
    this.attributeDataService.setAttributeData(this.componentId, 'layout', this.layout);
    this.attributeDataService.setAttributeData(this.componentId, 'environment', environment.production ? 'prod' : 'test');
    if (this.isValidNumberOfPosts()) {
      this.attributeDataService.setAttributeData(this.componentId, 'numberOfPosts', this.templateEditorUtilsService.intValueFor(this.numberOfPosts, this.DEFAULT_NUMBER_OF_POSTS));
    }
    this.attributeDataService.setAttributeData(this.componentId, 'showCaption', this.showCaption);
    this.attributeDataService.setAttributeData(this.componentId, 'hideVideos', this.hideVideos);
    const order = this.shuffleOrder ? 'shuffle' : 'date';
    this.attributeDataService.setAttributeData(this.componentId, 'order', order);
    if (this.isValidDuration()) {
      this.attributeDataService.setAttributeData(this.componentId, 'duration', this.duration);
    }
    this.attributeDataService.setAttributeData(this.componentId, 'scrollSpeed', this.scrollSpeed);
    this.attributeDataService.setAttributeData(this.componentId, 'hideOldPosts', this.hideOldPosts);
    this.attributeDataService.setAttributeData(this.componentId, 'refresh', this.refresh);
  }

  refreshUI() {
    this.changeDetectorRef.detectChanges();
  }

  toggleShowCaption() {
    this.showCaption = !this.showCaption;
    this.save();
  }

  toggleHideVideos() {
    this.hideVideos = !this.hideVideos;
    this.save();
  }

  toggleShuffleOrder() {
    this.shuffleOrder = !this.shuffleOrder;
    this.save();
  }

  addFeed(feedType: FeedType) {
    if (!this.featuresService.isFeatureAvailable(FeaturesService.FEATURE_SOCIAL_MEDIA_POSTS)) {
      this.featuresService.showUpgradePlanModal(FeaturesService.FEATURE_SOCIAL_MEDIA_POSTS);
      return;
    }

    this.currentView = 'add_feed';
    this.selectedFeed = {
      type: feedType,
      feed: {},
    };
  }

  confirmDisconnect(feedType: FeedType) {
    const disconnectMessage = `Any content that is using this account will stop working.`;

    return this.modalService.confirm(`Disconnect from ${this._getProviderName(feedType)}`, disconnectMessage)
    .then(() => {
      this._disconnect(feedType);
    })
    .catch(() => {});
  }

  removeFeed(feedType: FeedType) {
    this.feeds.delete(feedType);
    this.save();
  }

  async connect() {

    await this._authenticate();

    if (this.authenticateFailed) {
      return;
    }

    if (this.selectedFeed.type === FeedType.FB_PAGE) {
      await this._loadFacebookPages();
    } else {
      this._addToFeeds(this.selectedFeed.type, this.selectedFeed.feed);
      this.save();
      this.currentView = null;
    }
  }

  async addSelectedPage() {
    try {
      this.pagesApiFailed = false;
      this.spinner = true;

      if (!this.selectedPage?.id) {
        throw new Error('Page ID is missing');
      }

      const pageSelected = await this.socialMediaService.selectPage(this.userAccount, this.selectedPage.id);
      if (pageSelected) {
        this.selectedFeed.feed.pageId = this.selectedPage.id;
        this.selectedFeed.feed.pageName = this.selectedPage.name;
        this._addToFeeds(this.selectedFeed.type, this.selectedFeed.feed);
        this.currentView = null;
        this.save();
      } else {
        throw new Error('Backend error');
      }
    } catch (error) {
      this.pagesApiFailed = true;
      console.error(`Failed to select page ${this.selectedPage?.id}`, error.message);
    } finally {
      this.spinner = false;
    }
  }

  _addToFeeds(feedType: FeedType, feed: Feed) {
    this.feeds.set(feedType, feed);
  }

  async _loadFacebookPages() {
    try {
      this.spinner = true;
      this.pagesApiFailed = false;
      this.availablePages = [];
      this.selectedPage = null;
      this.currentView = 'select_page';

      this.availablePages = await this.socialMediaService.getPages(this.userAccount);
    } catch (error) {
      this.pagesApiFailed = true;
    } finally {
      this.spinner = false;
      this.refreshUI();
    }
  }

  private _connectAccount(provider, scope) {
    return this.authService.getConnectionStatus(provider, scope)
    .catch(() => {
      return this.authService.authenticate(provider, scope);
    });
  }

  async _authenticate() {
    try {
      this.spinner = true;
      this.authenticateFailed = false;

      const provider = this._getProvider(this.selectedFeed.type);
      const scope = this._getScope(this.selectedFeed.type);
      const feed = this.selectedFeed.feed;

      const authResult = await this._connectAccount(provider, scope);

      feed.userDisplayName = authResult.username;
      feed.userAccount = this.userAccount;
    } catch (err) {
      console.log(`Failed to connect to ${this.selectedFeed?.type}`, err);
      this.authenticateFailed = true;
      this.currentView = null;
    } finally {
      this.spinner = false;
      this.refreshUI();
    }
  }

  _getProvider(feedType: FeedType) {
    switch (feedType) {
      case FeedType.FB_PAGE:
        return FB_PAGE_PROVIDER;
      case FeedType.FB_USER:
        return FB_USER_PROVIDER;
      case FeedType.IG_PAGE:
        return IG_PAGE_PROVIDER;
      default:
        return FB_PAGE_PROVIDER;
    }
  }

  _getScope(feedType: FeedType) {
    switch (feedType) {
      case FeedType.FB_PAGE:
        return FB_PAGE_SCOPE;
      case FeedType.FB_USER:
        return FB_USER_SCOPE;
      case FeedType.IG_PAGE:
        return IG_PAGE_SCOPE;
      default:
        return '';
    }
  }

  _getProviderName(feedType: FeedType) {
    switch (feedType) {
      case FeedType.FB_PAGE:
        return 'Facebook business account';
      case FeedType.FB_USER:
        return 'Facebook consumer account';
      case FeedType.IG_PAGE:
        return 'Instagram business account';
      default:
        return '';
    }
  }

  _feedsToSources(feeds: Map<string, Feed>) {
    return Array.from(feeds, ([name, value]) => ({
      type: name,
      account: value.userAccount,
      pageId: value.pageId,
      pageName: value.pageName,
      userDisplayName: value.userDisplayName,
    }));
  }

  _sourcesToFeeds(sources) {
    const feeds = new Map<FeedType, Feed>();
    sources.forEach(source => {
      feeds.set(source.type, {
        userAccount: source.account,
        pageId: source.pageId,
        pageName: source.pageName,
        userDisplayName: source.userDisplayName,
      });
    });
    return feeds;
  }

  async _disconnect(feedType: FeedType) {
    try {
      this.revokeFailed = false;
      this.revokeFailedProviderName = this._getProviderName(feedType);

      // only user who added the feed have the right to disconnect it
      const feed = this.feeds.get(feedType);
      if (!feed || feed.userAccount !== this.userAccount) {
        this.revokeFailed = true;
        return;
      }

      this.spinner = true;

      this.removeFeed(feedType);

      this.templateEditorService.hasUnsavedChanges = true;

      await this.templateEditorService.save();

      const revoked = await this.socialMediaService.revoke(this.userAccount, this._getProvider(feedType));

      if (!revoked) {
        throw new Error('Failed to revoke account');
      }

    } catch (error) {

      console.error(`Failed to revoke account`, error.message);
      this.revokeFailed = true;

    } finally {
      this.spinner = false;
      this.refreshUI();
    }

  }

  isValidNumberOfPosts() {
    if (!Number.isInteger(this.numberOfPosts)) {
      return false;
    }
    return this.numberOfPosts >= this.MIN_POSTS && this.numberOfPosts <= this.MAX_POSTS;
  }

  isValidDuration() {
    if (!Number.isInteger(this.duration)) {
      return false;
    }
    return this.duration >= this.MIN_DURATION && this.duration <= this.MAX_DURATION;
  }

  saveInputIfValid(event: any) {
    if (event?.target?.validity?.valid) {
      this.save();
    }
  }

  onLayoutSelect(layout: LayoutType) {
    this.layout = layout;
    this.save();
  }

}
