import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';

export interface VideoStreamingValidationResult {
  status: 'VALID' | 'INVALID' | 'NOT_PUBLIC' | 'VIDEO_NOT_FOUND';
  hls?: string;
}

@Injectable({
  providedIn: 'root',
})
export class VideoStreamingValidationService {
  constructor(private httpClient: HttpClient) {}

  validateHLSUrl(url: string) {
    // TO-DO: IMPROVE REGEX
    const regex = /^(?:https?:\/\/)?(?:www\.)?(?:.)+(?:\.m3u8)$/;
    return regex.test(url);
  }

  async validateHLSContent(
    url: string
  ): Promise<VideoStreamingValidationResult> {
    try {
      // TO-DO: LAZY LOAD hls.js LIBRARY

      // TO-DO: VALIDATE STREAM CONTENT AND DETECT CAPTIONS AND AUDIO TRACKS

      return { status: 'VALID' };
    } catch (err) {
      return this.validateApiError(err);
    }
  }

  private validateApiError(err: {
    status: number;
  }): VideoStreamingValidationResult {
    if (err.status === 401) return { status: 'NOT_PUBLIC' };
    return { status: 'INVALID' };
  }
}
