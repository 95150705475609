import { Component } from '@angular/core';

import { CompanyStateService } from 'src/app/auth/services/company-state.service';
import { UserStateService } from 'src/app/auth/services/user-state.service';
import { ModalService } from 'src/app/components/modals/modal.service';
import { RvshareAppDetectionService } from 'src/app/screen-sharing/services/rvshare-app-detection.service';
import { CompanyAccessService } from '../../services/company-access.service';
import { SelectCompanyComponent } from '../select-company/select-company.component';

@Component({
  selector: 'company-buttons',
  templateUrl: './company-buttons.component.html',
  styleUrls: ['./company-buttons.component.scss']
})
export class CompanyButtonsComponent {


  constructor(public userStateService: UserStateService,
    public companyStateService: CompanyStateService,
    public modalService: ModalService,
    public companyAccessService: CompanyAccessService,
    public rvshareAppDetectionService: RvshareAppDetectionService
  ) { }

  selectCompany() {
    return this.modalService.showLargeDialog(
      SelectCompanyComponent,
      null,
      { panelClass: ['select-company'], height: '95%' }
    );
  }

  isScreenShareApp() {
    return this.rvshareAppDetectionService.isScreenShareApp();
  }

}