<form id="confirmForm">
  <div class="tw-flex tw-justify-end tw-mr-2">
    <button type="button" mat-icon-button class="close" [mat-dialog-close]="false" data-close="modal" aria-label="Click to close this modal">
      <streamline-icon ariaLabel="Click to close this modal" name="close" width="12" height="12"></streamline-icon>
    </button>
  </div>
  <h4>Keep everyone in the know!</h4>
  <mat-dialog-content>
    <div class="plan-features">
      <p>Unlock this feature by subscribing to Rise Vision.</p>
      <p><strong>By subscribing, you’ll get access to:</strong></p>
      <ul >
        <li><mat-icon aria-hidden="true" svgIcon="checkmark" />&nbsp;Hundreds of professionally-designed templates.</li>
        <li><mat-icon aria-hidden="true" svgIcon="checkmark" />&nbsp;Dozens of content integrations.</li>
        <li><mat-icon aria-hidden="true" svgIcon="checkmark" />&nbsp;Share to websites, personal computers, and more.</li>
        <li><mat-icon aria-hidden="true" svgIcon="checkmark" />&nbsp;Free, world-class email and phone support.</li>
      </ul>
      <p>Digital signage doesn’t have to be difficult. We make it easy or your money back. 30 days risk-free.</p>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end" class="tw-gap-4">
    <div class="tw-flex-1">
      <button
        id="cancelButton"
        mat-stroked-button
        type="button"
        class="tw-w-full"
        [mat-dialog-close]="false">
        Cancel
      </button>
    </div>
    <div class="tw-flex-1">
      <button mat-flat-button
        type="button"
        color="primary"
        id="confirm-primary"
        class="tw-w-full"
        [mat-dialog-close]="true">
        <span>Subscribe</span>
      </button>
    </div>
  </mat-dialog-actions>
</form>
