<div
  class="attribute-editor-component video-streaming-container"
  rvSpinner="video-streaming-editor-loader"
  [rvShowSpinner]="spinner"
>
  <div class="attribute-editor-row" [style.marginBottom]="'24px'">
    Supports HLS (HTTP Live Streaming) protocol. Videos won't be shown in
    preview but will play on your Display.
  </div>
  <div class="attribute-editor-row">
    <form>
      <div
        class="form-group has-feedback"
        [ngClass]="{
          'has-error': validationStatus && validationStatus !== 'VALID',
          'has-success': validationStatus === 'VALID' && source !== ''
        }"
      >
        <label class="control-label" for="te-video-streaming-src"
          >Source:</label
        >
        <input
          type="text"
          id="te-video-streaming-src"
          name="te-video-streaming-src"
          class="form-control u_ellipsis"
          [(ngModel)]="source"
          placeholder="Enter URL"
          (debounceEvent)="saveSource()"
          aria-label="HLS video streaming source URL"
          maxlength="2048"
          [attr.aria-invalid]="validationStatus && validationStatus !== 'VALID'"
        />
        <streamline-icon
          class="overlay-icon form-control-feedback"
          aria-hidden="true"
          name="{{
            validationStatus && validationStatus !== 'VALID'
              ? 'exclamation'
              : 'checkmark'
          }}"
          width="14"
          height="12"
        ></streamline-icon>
        <p class="help-block">
          @switch (validationStatus) { @case ('INVALID') {
          <span>Please provide a valid HLS URL.</span>
          } @case ('VIDEO_NOT_FOUND') {
          <span>Video streaming not found.</span>
          } }
        </p>
      </div>
    </form>
  </div>

  <div class="attribute-editor-row">
    <div id="video-streaming-component-volume">
      <label [style.marginBottom]="0">Volume:</label>
      <div class="w-100 flex flex-row gap-3">
        <mat-icon aria-hidden="true" svgIcon="volume" class="mr-1"></mat-icon>
        <mat-slider
          min="0"
          max="100"
          step="1"
          color="accent"
          aria-label="Video volume control"
          class="flex-grow"
        >
          <input
            matSliderThumb
            [(ngModel)]="volume"
            (valueChange)="saveVolume()"
            [attr.aria-valuetext]="volume + ' percent volume'"
          />
        </mat-slider>
        <div class="volume">{{ volume }}</div>
      </div>
    </div>
  </div>

  <!-- TO-DO: ADD CAPTIONS AND AUDIO TRACK FIELDS -->

  <!-- TO-DO: ADD HTML VIDEO TAG -->

</div>
