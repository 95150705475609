import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonHeaderModule } from '../common-header/common-header.module';
import { UIRouterModule } from '@uirouter/angular';
import { routes } from './app-common.routes';
import { AppComponent } from './components/app/app.component';
import { ViewportComponent } from './viewport/viewport.component';

@NgModule({
  declarations: [
    AppComponent,
    ViewportComponent
  ],
  imports: [
    CommonModule,
    CommonHeaderModule,
    UIRouterModule.forChild(routes)
  ],
  exports: [
    AppComponent
  ]
})
export class AppCommonModule { }
