import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DisplayService } from '../../services/display.service';
import { ModalService } from 'src/app/components/modals/modal.service';
import { ScreenshotService } from '../../services/screenshot.service';
import { DisplayControlService } from '../../services/display-control.service';
import { StateService, UIRouterGlobals } from '@uirouter/angular';

@Component({
  selector: 'display-details',
  templateUrl: './display-details.component.html',
  styleUrls: ['./display-details.component.scss']
})
export class DisplayDetailsComponent implements OnInit {

  selectedSchedule: any = null;

  displayControlType: string;

  @ViewChild('displayForm') displayForm: NgForm;

  constructor(private ngZone: NgZone,
    public displayService: DisplayService,
    public displayControlService: DisplayControlService,
    private screenshotService: ScreenshotService,
    private modalService: ModalService,
    private uiRouterGlobals: UIRouterGlobals,
    private stateService: StateService
  ) { }

  ngOnInit(): void {
    const displayId = this.uiRouterGlobals.params.displayId;
    this.displayService.init();
    this.displayService.getDisplay(displayId).then(() => {
      if (this.displayService.hasSchedule(this.displayService.display)) {
        this.selectedSchedule = {
          id: this.displayService.display.scheduleId,
          name: this.displayService.display.scheduleName,
          companyId: this.displayService.display.companyId
        };
      }

      if (!this.displayService.display.playerProAuthorized) {
        this.displayService.display.monitoringEnabled = false;
      }

      if (this.displayService.display.onlineStatus === "online") {
        this.screenshotService.requestScreenshot();
      } else {
        this.screenshotService.loadScreenshot();
      }

      this.displayControlService.isDisplayControlEnabled(displayId).then((enabled) => {
        this.displayService.display.isDisplayControlEnabled = enabled;
      });

      this.displayControlService.getInterfaceType(this.displayService.display.id).then((type) => {
        this.displayControlType = type;
      });

      setTimeout(() => {
        this.ngZone.run(() => {});
      });
    });
  }

  addDisplay() {
    if (!this.displayForm.dirty) {
      this.stateService.go('apps.displays.add');
    } else {
      this.modalService.confirm('Unsaved Changes',
          'You have unsaved changes. Do you want to Save or Discard them?',
          'Save', 'Discard')
        .then(() => {
          // do what you need if user presses ok
          this.save().then(() => {
              this.stateService.go('apps.displays.add');
            });
        }, () => {
          // do what you need to do if user cancels
            this.stateService.go('apps.displays.add');
        });

    }
  }

  save() {
    if (!this.displayForm.valid) {
      console.info('form not valid: ', this.displayForm.errors);

      return Promise.reject();
    } else {
      return this.displayService.updateDisplay(this.selectedSchedule);
    }
  }

}
