<div class="input-group">
  <span tabindex="0" class="input-group-addon" (click)="doSearch()">
    <streamline-icon name="magnifier" width="16" height="16" ariaLabel="Do search"></streamline-icon>
  </span>
  <input tabindex="0" id="{{ filterConfig.id }}" type="text" class="form-control" placeholder="{{ filterConfig.placeholder }}"
    [(ngModel)]="search.query" (keyup.enter)="searchAction(0)" (ngModelChange)="searchAction(1000)" [focusMe]="focus">
  <span tabindex="0" class="input-group-addon" (click)="reset()" (keyup.enter)="reset()">
    <streamline-icon name="close" width="12" height="12" ariaLabel="Clear search"></streamline-icon>
  </span>
</div>
